export default function UnsupportedBrowser() {
  return (
    <div
      style={{
        fontFamily: "Arial,sans-serif",
        margin: "0 auto",
        maxWidth: "80%",
        textAlign: "center",
        padding: "120px 0",
      }}
    >
      <h1>Please upgrade your browser to access HoltUsed.com.</h1>
      <p>
        We recommend using the latest version of Chrome, Edge, Firefox, or
        Safari.
      </p>
    </div>
  );
}
