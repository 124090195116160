import { useCallback } from "react";
import {
  element,
  elementType,
  func,
  node,
  oneOfType,
  string,
} from "prop-types";

import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

import ErrorBoundaryFallbackDefault from "./components/ErrorBoundary.fallback-default";

function ErrorBoundary({
  children,
  fallback: Fallback = ErrorBoundaryFallbackDefault,
  location = "default",
  prerenderReady,
}) {
  const getErrorProps = useCallback((error, resetError) => {
    let { name = "Error", message = "Unknown error" } = error || {};
    const isChunkError = /ChunkLoadError/gi.test(name);

    if (isChunkError) {
      message =
        "Clear your browser cache and reload to access the latest version of HoltUsed.com.";
    }

    return { isChunkError, message, name, resetError };
  }, []);

  const handleBeforeCapture = useCallback(
    scope => {
      scope.setTag("location", location);
    },
    [location]
  );

  const handleError = useCallback(() => {
    if (!!prerenderReady) {
      prerenderReady();
    }
  }, [prerenderReady]);

  return (
    <SentryErrorBoundary
      beforeCapture={handleBeforeCapture}
      fallback={({ error, resetError }) => (
        <Fallback {...getErrorProps(error, resetError)} />
      )}
      onError={handleError}
    >
      {children}
    </SentryErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
  fallback: oneOfType([element, elementType, node, func, string]),
  location: string,
  prerenderReady: func,
};

export default ErrorBoundary;
