import { createRoot } from "react-dom/client";
import { lazy, Suspense } from "react";

import ErrorBoundary from "./modules/error-boundary";
import UnsupportedBrowser from "./modules/unsupported-browser";

const EntryPoint = isModernBrowser()
  ? lazy(function () {
      return import("./modules/redux-provider");
    })
  : UnsupportedBrowser;

const root = createRoot(document.getElementById("root"));

root.render(
  <ErrorBoundary>
    <Suspense fallback={null}>
      <EntryPoint />
    </Suspense>
  </ErrorBoundary>
);

/*************/

/**
 * Checks if browser is modern
 * Uses ES6 module support as a proxy for determining "modernness"
 *
 * @returns bool
 * @public
 */
function isModernBrowser() {
  const script = document.createElement("script");
  return "noModule" in script;
}
