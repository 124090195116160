import { bool, string } from "prop-types";

// NOTE:
// intentionally uses minimal styles and dependencies
// in order to prevent secondary errors

function ErrorBoundaryFallbackDefault({ isChunkError, message, name }) {
  return (
    <div
      style={{
        fontFamily: "Arial,sans-serif",
        margin: "0 auto",
        maxWidth: "80%",
        textAlign: "center",
        padding: "120px 0",
      }}
    >
      <h1>{isChunkError ? "Site Update" : "Error"}</h1>
      <p>
        {!isChunkError && name !== "Error" ? `${name}: ` : ""}
        {message}
      </p>
      {!isChunkError && (
        <p>
          <a href="/">Back to Home Page</a>
        </p>
      )}
    </div>
  );
}

ErrorBoundaryFallbackDefault.propTypes = {
  isChunkError: bool,
  message: string,
  name: string,
};

export default ErrorBoundaryFallbackDefault;
